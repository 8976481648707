// Updated: 19-08-2024
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from '@mui/material';
import { format, isAfter, isSameDay, subDays, isBefore } from 'date-fns';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../Styles/style.css";

import NavBar from "../Components/Appbar";
import SectionContainer from "../Components/SectionContainer";
import { jwtDecode } from "jwt-decode";
import baseurl from "../Assets/baseurl";

const DayBook = () => {
    const [value, setValue] = useState(0);
    const [yesterdayClosingBalance, setClosingBalance] = useState(0);
    const [ledgerRecords, setLedgerRecords] = useState([]);
    const [expenseRecords, setExpenseRecords] = useState([]);
    const [inventoryRecords, setInventoryRecords] = useState([]);
    const [creditBuyRecords, setCreditBuyRecords] = useState([]);
    
    const [bankRecords, setBankRecords] = useState([]); // New state for bank records
    const [transactions, setTransactions] = useState([]);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const token = localStorage.getItem('token');
    const verifyToken = jwtDecode(token);
    const email = verifyToken.email;
    const cnic = verifyToken.cnic;

    useEffect(() => {
        fetchLedgerData();
        fetchExpenseData();
        fetchInventoryData();
        fetchCreditBuyData();
        fetchBankData(); // Fetch bank data
    }, [value]);

    const sumYesterdayCreditsAndDebits = () => {
        const yesterday = subDays(new Date(), 1);

        const allRecords = [
            ...ledgerRecords,
            ...expenseRecords,
            ...inventoryRecords,
            ...creditBuyRecords,
            ...bankRecords // Include bank records
        ];

        const { totalCredits, totalDebits } = allRecords
            .filter(record => isSameDay(record.date, yesterday))
            .reduce((totals, record) => {
                totals.totalCredits += (record.credited || 0);
                totals.totalDebits += (record.debited || 0);
                return totals;
            }, { totalCredits: 0, totalDebits: 0 });

        return totalCredits - totalDebits;
    };

    useEffect(() => {
        const closing = sumYesterdayCreditsAndDebits();
        setClosingBalance(closing);
    }, [ledgerRecords, expenseRecords, inventoryRecords, creditBuyRecords, bankRecords]);

    const fetchLedgerData = async () => {
        try {
            const response = await fetch(`${baseurl}/ledger/ledger/${email}`);
            const data = await response.json();

            const processedData = data.parties.flatMap(party =>
                party.items.map(item => {
                    const itemDate = new Date(item.date);
                    const isRefund = item.amount < 0;

                    return {
                        id: item._id,
                        type: 'Ledger',
                        itemName: item.itemName,
                        amount: Math.abs(item.amount),
                        date: itemDate.toString() === 'Invalid Date' ? new Date() : itemDate,
                        credited: isRefund ? Math.abs(item.amount) : 0,
                        debited: isRefund ? 0 : Math.abs(item.amount)
                    };
                })
            );

            setLedgerRecords(processedData);
        } catch (error) {
            console.error('Error fetching ledger data:', error);
        }
    };

    const fetchExpenseData = async () => {
        try {
            const baseUrl = `${baseurl}/expense/`;
            const endpoint = value === 0 ? 'getlisttodayExpense' : value === 1 ? 'getlistWeeklyExpense' : 'getlistMonthlyExpense';
            const response = await fetch(`${baseUrl}${endpoint}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            const processedData = data.map(expense => {
                const expenseDate = new Date(expense.timestamp);

                return {
                    id: expense._id,
                    type: 'Expense',
                    itemName: expense.description,
                    amount: Math.abs(expense.amount),
                    date: expenseDate.toString() === 'Invalid Date' ? new Date() : expenseDate,
                    credited: 0,
                    debited: Math.abs(expense.amount)
                };
            });
            setExpenseRecords(processedData);
        } catch (error) {
            console.error('Error fetching expense data:', error);
        }
    };

    const fetchInventoryData = async () => {
        try {
            const response = await fetch(`${baseurl}/mobile/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ Email: email, CNIC: cnic })
            });
            const { data } = await response.json();
            const processedData = data.flatMap(record =>
                record.Mobile
                    .filter(mobile => mobile.PurchaseDate || mobile.SellingDate)
                    .flatMap(mobile => {
                        const records = [];
                        if (mobile.PurchaseDate) {
                            records.push({
                                id: mobile._id,
                                type: 'Purchase',
                                itemName: `${mobile.BrandName} ${mobile.Model}`,
                                amount: Math.abs(mobile.PurchasingPrice),
                                date: new Date(mobile.PurchaseDate),
                                credited: 0,
                                debited: Math.abs(mobile.PurchasingPrice)
                            });
                        }
                        if (mobile.SellingDate) {
                            records.push({
                                id: mobile._id,
                                type: 'Sale',
                                itemName: `${mobile.BrandName} ${mobile.Model}`,
                                amount: Math.abs(mobile.SellingPrice),
                                date: new Date(mobile.SellingDate),
                                credited: Math.abs(mobile.SellingPrice),
                                debited: 0
                            });
                        }
                        return records;
                    })
            );
            setInventoryRecords(processedData);
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        }
    };

    const fetchCreditBuyData = async () => {
        try {
            const response = await fetch(`${baseurl}/credit-buy/credit-buy/${email}`);
            const data = await response.json();
            
            const processedData = data.parties.flatMap(party =>
                party.items.map(item => {
                    const itemDate = new Date(item.date);
                    const isRefund = item.amount < 0;
                    return {
                        id: item._id,
                        type: 'CreditBuy',
                        itemName: item.itemName,
                        amount: Math.abs(item.amount),
                        date: itemDate.toString() === 'Invalid Date' ? new Date() : itemDate,
                        credited: isRefund ? Math.abs(item.amount) : 0,
                        debited: isRefund ? 0 : Math.abs(item.amount)
                    };
                })
            );
            setCreditBuyRecords(processedData);
        } catch (error) {
            console.error('Error fetching credit buy data:', error);
        }
    };

    const fetchBankData = async () => {
        try {
            const response = await axios.get(`${baseurl}/bank/transactions/${email}`);
            const deposits = response.data.deposits || [];
            const withdrawals = response.data.withdrawals || [];
    
            const combinedTransactions = [
                ...deposits.map(transaction => ({
                    _id: transaction._id,
                    amount: transaction.amount,
                    date: transaction.date,
                    description: transaction.description,
                    type: 'Bank',
                    credited: 0, // Deposit means money is debited from the account
                    debited: transaction.amount,
                })),
                ...withdrawals.map(transaction => ({
                    _id: transaction._id,
                    amount: transaction.amount,
                    date: transaction.date,
                    description: transaction.description,
                    type: 'Bank',
                    credited: transaction.amount, // Withdraw means money is credited to the account
                    debited: 0,
                }))
            ];
    
            setBankRecords(combinedTransactions);
        } catch (error) {
            console.error('Error fetching bank data:', error);
        }
    };
    
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterRecords = (records) => {
        if (!startDate || !endDate) return records;
        return records.filter(record =>
            record.date && (isAfter(record.date, startDate) && isBefore(record.date, endDate)) ||
            isSameDay(record.date, startDate) || isSameDay(record.date, endDate)
        );
    };

    const calculateTotals = (filteredRecords) => {
        const creditedTotal = filteredRecords.reduce((acc, record) => acc + (record.credited || 0), 0);
        const debitedTotal = filteredRecords.reduce((acc, record) => acc + (record.debited || 0), 0);
        return { creditedTotal, debitedTotal };
    };

    const getTabContent = () => {
        const filteredLedger = filterRecords(ledgerRecords);
        const filteredExpenses = filterRecords(expenseRecords);
        const filteredInventory = filterRecords(inventoryRecords);
        const filteredCreditBuy = filterRecords(creditBuyRecords);
        const filteredBank = filterRecords(bankRecords);
    
        const { creditedTotal, debitedTotal } = calculateTotals([
            ...filteredLedger,
            ...filteredExpenses,
            ...filteredInventory,
            ...filteredCreditBuy,
            ...filteredBank
        ]);
    
        return (
            <>
                <Typography variant="h6" gutterBottom>
                    Debited Amount: {debitedTotal}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Credited Amount: {creditedTotal}
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Credited</TableCell>
                                <TableCell>Debited</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...filteredLedger, ...filteredExpenses, ...filteredInventory, ...filteredCreditBuy, ...filteredBank]
                                .sort((a, b) => new Date(b.date) - new Date(a.date))
                                .map((record, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{record.type}</TableCell>
                                        <TableCell>{record.itemName || record.description}</TableCell>
                                        <TableCell>{format(record.date, 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{record.credited ? `Rs. ${record.credited}` : ''}</TableCell>
                                        <TableCell>{record.debited ? `Rs. ${record.debited}` : ''}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };
    
    const generatePDF = () => {
        const doc = new jsPDF();
    
        const filteredLedger = filterRecords(ledgerRecords);
        const filteredExpenses = filterRecords(expenseRecords);
        const filteredInventory = filterRecords(inventoryRecords);
        const filteredCreditBuy = filterRecords(creditBuyRecords);
        const filteredBank = filterRecords(bankRecords);
    
        // Prepare all records for PDF export
        const allRecords = [
            ...filteredLedger,
            ...filteredExpenses,
            ...filteredInventory,
            ...filteredCreditBuy,
            ...filteredBank
        ]
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map(record => {
                let name = record.itemName || record.description || '';
                if (record.type === 'Bank') {
                    name = `${record.description}`;
                }
                return [
                    record.type,
                    name,
                    format(record.date, 'dd/MM/yyyy'),
                    record.credited ? `Rs. ${record.credited}` : '',
                    record.debited ? `Rs. ${record.debited}` : ''
                ];
            });
    
        // Generate PDF
        doc.autoTable({
            head: [['Type', 'Name', 'Date', 'Credited', 'Debited']],
            body: allRecords
        });
    
        doc.save('DayBook.pdf');
    };
    

    return (
        <>
            <NavBar />
            <Box className="dayBook">
            <SectionContainer title={"My Day Book"} showFilter={false} showSearch={false} filter={false} onFilterChange={false} />
                <Container>
                    <Box sx={{ width: '100%', marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                <Tab value={0} label="Today" />
                                <Tab value={1} label="Weekly" />
                                <Tab value={2} label="Monthly" />
                            </Tabs>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Start Date"
                                    dateFormat="dd/MM/yyyy"
                                    className="custom-datepicker"
                                />
                                <Typography style={{ margin: '0 8px' }}>to</Typography>
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="End Date"
                                    dateFormat="dd/MM/yyyy"
                                    className="custom-datepicker"
                                />
                            </Box>
                        </Box>
                        <Button onClick={generatePDF} variant="contained" color="secondary">
                            Export PDF
                        </Button>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        Closing Balance for Yesterday: {yesterdayClosingBalance}
                    </Typography>
                    {getTabContent()}
                </Container>
            </Box>
        </>
    );
};

export default DayBook;
