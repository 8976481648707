import React, { useState, useEffect } from "react";
import Appbar from "../Components/Appbar";
import SectionContainer from "../Components/SectionContainer";

import {
    Backdrop,
    Box,
    Button,
    Fade,
    
    Modal,
    TextField,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton
} from "@mui/material";
import {  Edit, Delete } from "@mui/icons-material";
import {jwtDecode} from "jwt-decode";
import baseurl from "../Assets/baseurl"; // Import corrected

export default function ManageParty() {
    const [parties, setParties] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedParty, setSelectedParty] = useState(null);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [PartyName, setPartyName] = useState('');
    const [PartyCNIC, setPartyCNIC] = useState('');
    const [PartyAddress, setAddress] = useState('');
    const [PartyContactNo, setContactNo] = useState('');

    useEffect(() => {
        fetchParties();
    }, []);

    const fetchParties = async () => {
        try {
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const response = await fetch(`${baseurl}/party/getAllParties`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: verifyToken.email }) // Replace with actual email
            });
            const data = await response.json();
            if (Array.isArray(data)) {
                setParties(data);
            } else {
                console.error('Error: API did not return an array');
                setParties([]);
            }
        } catch (error) {
            console.error('Error fetching parties:', error);
        }
    };

    const handleOpen = (party = null) => {
        if (party) {
            setSelectedParty(party);
            setPartyName(party.name);
            setPartyCNIC(party.cnic);
            setAddress(party.address);
            setContactNo(party.contactNo);
            setEditMode(true);
        } else {
            setSelectedParty(null);
            setPartyName('');
            setPartyCNIC('');
            setAddress('');
            setContactNo('');
            setEditMode(false);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddOrUpdateParty = async () => {
        const token = localStorage.getItem('token');
        const verifyToken = jwtDecode(token);
        if (editMode) {
            try {

                const response = await fetch(`${baseurl}/party/updateParty`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: verifyToken.email, // Replace with actual email
                        partyId: selectedParty._id,
                        name: PartyName,
                        cnic: PartyCNIC || 'NA',
                        address: PartyAddress || 'NA',
                        contactNo: PartyContactNo || 'NA'
                    })
                });
                if (response.ok) {
                    fetchParties();
                    handleClose();
                } else {
                    console.error('Error updating party:', response.statusText);
                }
            } catch (error) {
                console.error('Error updating party:', error);
            }
        } else {
            try {
                const response = await fetch(`${baseurl}/party/createParty`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: verifyToken.email, // Replace with actual email
                        name: PartyName,
                        cnic: PartyCNIC || 'NA',
                        address: PartyAddress || 'NA',
                        contactNo: PartyContactNo || 'NA'
                    })
                });
                if (response.ok) {
                    fetchParties();
                    handleClose();
                } else {
                    console.error('Error adding party:', response.statusText);
                }
            } catch (error) {
                console.error('Error adding party:', error);
            }
        }
    };

    const handleDeleteParty = async (partyId) => {
        const confirmed = window.confirm("Are you sure you want to delete this party?");
        const token = localStorage.getItem('token');
        const verifyToken = jwtDecode(token);
        if (confirmed) {
            try {
                const response = await fetch(`${baseurl}/party/deleteParty`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: verifyToken.email, // Replace with actual email
                        partyId
                    })
                });
                if (response.ok) {
                    fetchParties();
                } else {
                    console.error('Error deleting party:', response.statusText);
                }
            } catch (error) {
                console.error('Error deleting party:', error);
            }
        }
    };

    const filteredParties = Array.isArray(parties) ? parties.filter(party =>
        party.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        party.cnic.includes(searchQuery) ||
        party.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        party.contactNo.includes(searchQuery)
    ) : [];

    return (
        <>
            <Appbar />
            <SectionContainer title={"Manage My Parties"} showFilter={false} showSearch={false} filter={false} onFilterChange={false} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '20px 0px' }}>
                <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ margin: '14px 0px' }}>
                    Add Party
                </Button>
            </div>
            <Box sx={{ width: '75%', padding: { xs: '0 10px', sm: '0 20px' }, marginBottom: 2 }}>
                <TextField
                    label="Search Party Name"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                />
            </Box>
            <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><span style={{ fontWeight: 'bold' }}>Party Name</span></TableCell>
                                <TableCell align="left"><span style={{ fontWeight: 'bold' }}>CNIC</span></TableCell>
                                <TableCell align="left"><span style={{ fontWeight: 'bold' }}>Contact No</span></TableCell>
                                <TableCell align="left"><span style={{ fontWeight: 'bold' }}>Address</span></TableCell>
                                <TableCell align="left"><span style={{ fontWeight: 'bold' }}>Actions</span></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredParties.map(party => (
                                <TableRow key={party._id}>
                                    <TableCell>{party.name}</TableCell>
                                    <TableCell align="left">{party.cnic}</TableCell>
                                    <TableCell align="left">{party.contactNo}</TableCell>
                                    <TableCell align="left">{party.address}</TableCell>
                                    <TableCell align="left">
                                        <IconButton onClick={() => handleOpen(party)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteParty(party._id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/*Add/Edit Party Modal*/}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="party-modal-title"
                aria-describedby="party-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography variant="h6" gutterBottom>{editMode ? 'Edit Party Info' : 'Add Party Info'}</Typography>
                        <div style={{ display: 'flex', marginBottom: '10px', flexDirection: "column" }}>
                            <TextField
                                label="Party Name *"
                                value={PartyName}
                                onChange={(e) => setPartyName(e.target.value)}
                                fullWidth
                                placeholder='Party Name'
                                margin="dense"
                                style={{ marginRight: '10px' }}
                            />
                            <TextField
                                label="CNIC"
                                value={PartyCNIC}
                                type='text'
                                placeholder='CNIC'
                                onChange={(e) => setPartyCNIC(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                label="Address"
                                value={PartyAddress}
                                type='text'
                                placeholder='Address'
                                onChange={(e) => setAddress(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                label="Contact No *"
                                value={PartyContactNo}
                                type='text'
                                placeholder='Contact No'
                                onChange={(e) => setContactNo(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </div>
                        <Button variant="contained" color="primary" onClick={handleAddOrUpdateParty} style={{ marginTop: '10px' }}>
                            {editMode ? 'Update Party' : 'Add Party'}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

