import React, { useState, useEffect } from "react";
import Appbar from "../Components/Appbar";
import SectionContainer from "../Components/SectionContainer";
import { jwtDecode } from 'jwt-decode';
import {
    Backdrop,
    Box,
    Button,
    Fade,
    Modal,
    TextField,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton
} from "@mui/material";
import DatePicker from "rsuite/DatePicker";
import "rsuite/dist/rsuite.min.css";
import LedgerCard from "../Components/LedgerCard";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import baseurl from "../Assets/baseurl";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import LedgerHistory from "../Components/LedgerHistory";
import {HistoryOutlined, PaymentsOutlined} from "@mui/icons-material";
import RefundModal from "../Components/RefundModal";
import RefundHistoryModal from "../Components/RefundHistoryModal";

export default function ManageLedger() {
    const [ledgerItems, setLedgerItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [currentPartyId, setCurrentPartyId] = useState(null);
    const [partyName, setPartyName] = useState('');
    const [items, setItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [historyOpen, setHistoryOpen] = useState(false);
    const [RefundHistory, setRefundHistory] = useState(false);
    const [historyItems, setHistoryItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [refundData, setRefundData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [partyId,setPartyId]=useState(null);
    const [partname,setpartname]=useState(null);


    // Fetch ledger items from the backend
    useEffect(() => {
        fetchLedgerItems();
    }, []);

    const fetchLedgerItems = async () => {
        try {
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;

            const response = await axios.get(`${baseurl}/ledger/ledger/${email}`, {
                headers: {
                    // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                }
            });

            if (response.status === 200) {
                const data = response.data;
                setLedgerItems(data.parties || []);
            } else {
                console.error('Failed to fetch ledger items');
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code outside the range of 2xx
                console.error('Error response:', error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };

    // Handle opening the "Add Ledger" modal
    const handleOpen = () => {
        setOpen(true);
    };

    // Handle closing the "Add Ledger" modal
    const handleClose = () => {
        setOpen(false);
    };
    const handleRefundCloseModal = () => {
        setRefundHistory(false);
    };

    // Handle opening the "Edit Ledger" modal
    const handleEditOpen = (party) => {
        setCurrentPartyId(party._id);
        setPartyName(party.partyName);
        setItems(party.items);
        setEditOpen(true);
    };

    // Handle closing the "Edit Ledger" modal
    const handleEditClose = () => {
        setEditOpen(false);
    };

    // Add a new ledger or update existing party
    const handleAddLedger = async () => {
        try {
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;

            const response = await axios.post(`${baseurl}/ledger/ledger`, {
                email,
                partyName,
                items
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                }
            });

            if (response.status === 200 || response.status === 201) { // Assuming 201 for created
                fetchLedgerItems(); // Refresh the list
                setPartyName('');
                setItems([]);
                handleClose();
            } else {
                console.error('Failed to add or update ledger');
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code outside the range of 2xx
                console.error('Error response:', error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };

    // Update the items list in a ledger
    const handleAddItem = async () => {
        try {
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;

            const response = await axios.put(`${baseurl}/ledger/ledger/${email}/${partyName}`, {
                items
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                }
            });

            if (response.status === 200) {
                fetchLedgerItems(); // Refresh the list
                setPartyName('');
                setItems([]);
                handleEditClose();
            } else {
                console.error('Failed to update items');
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code outside the range of 2xx
                console.error('Error response:', error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };

    // Update specific item fields
    const handleUpdateItem = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index] = { ...updatedItems[index], [field]: value };
        setItems(updatedItems);

    };

    // Add another item to the items list
    const handleAddAnotherItem = () => {
        setItems([...items, { itemName: '',IMEI:'', amount: '', date: new Date() }]);
    };

    // Delete an item from the items list
    const handleDeleteItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    //Ledger History of A Party

    const handleLedgerHistory=async (partyName)=>{
        try {
            console.log(items)
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;

            const response = await axios.post(`${baseurl}/ledger/getPartyItem`, {
                email,
                partyName
            });

            if (response.status === 200) {

                setHistoryItems(response.data.items || []);
                setHistoryOpen(true);
            } else {
                console.error('Failed to fetch history');
            }
        } catch (error) {
            console.error('Error fetching history:', error);
        }
    }
    const handleRefundHistoryOpenModal = async (partyId) => {
        setLoading(true);
        setError(null);

        try {
            // Fetch refund history from API
            const response = await axios.get(`${baseurl}/ledger/refund-history/${partyId}`);
            setRefundData(response.data);
            setModalOpen(true);
        } catch (err) {
            console.error("Error fetching refund history:", err);
            setError("Failed to fetch refund history.");
        } finally {
            setLoading(false);
        }
    };

    const handleRefundHistoryCloseModal = () => {
        setModalOpen(false);
    };

    const handleRefundHistory=async (partId,partName)=>{

        setPartyId(partId)
        setpartname(partName)

        setRefundHistory(true)
    }
    const handleRefundSubmit=async ()=>{

        window.location.reload()
    }


    // Delete a party from the ledger
    const handleDeleteParty = async (partyId) => {

        const confirmed = window.confirm("Are you sure you want to delete this party?");
        if (confirmed) {
            try {
                const token = localStorage.getItem('token');
                const verifyToken = jwtDecode(token);
                const email = verifyToken.email;

                const response = await axios.delete(`${baseurl}/ledger/ledger/${email}/${partyId}`, {
                    headers: {
                        // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                    }
                });


                if (response.status === 200) {
                    fetchLedgerItems(); // Refresh the list
                } else {
                    console.error('Failed to delete party');
                }
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code outside the range of 2xx
                    console.error('Error response:', error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Error request:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error message:', error.message);
                }
                console.error('Error config:', error.config);
            }
        }
    };



    // Calculate total amount for items
    const getTotalAmount = (items) => items.reduce((total, item) => total + parseFloat(item.amount || 0), 0);

    // Filter ledger items based on search query
    const filteredLedgerItems = ledgerItems.filter(party =>
        party.partyName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: 800 }, // Increased width for larger screens
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const responsiveTextFieldStyle = {
        width: 'calc(100% - 16px)', // Adjust the width to accommodate the margin and padding
    };

    const scrollableContainerStyle = {
        maxHeight: '400px', // Adjust as needed
        overflowY: 'auto',
        marginBottom: '16px', // Space between scrollable area and button
    };
    const today = new Date().toISOString().split('T')[0];

    return (
        <>
            <Appbar />
            <SectionContainer title={"Manage My Ledger"} showFilter={false} showSearch={false} filter={false} onFilterChange={false} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 0px',
                }}
            >
                <Box
                    sx={{
                        width: { xs: '90%', sm: '70%', md: '50%', lg: '30%' },
                        mt: 8,
                        mb: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 8,
                    }}
                >
                    <LedgerCard />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '20px 0px',
                    }}
                >
                    <Button variant="contained" color="primary" onClick={handleOpen} sx={{ margin: '14px 0px' }}>
                        Add Ledger
                    </Button>
                </Box>

                <Box sx={{ width: '75%', padding: { xs: '0 10px', sm: '0 20px' }, marginBottom: 2 }}>
                    <TextField
                        label="Search Party Name"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                    />
                </Box>

                <Box sx={{ width: '100%', overflowX: 'auto', overflowY: 'auto', padding: { xs: '0 10px', sm: '0 20px' } }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><span style={{ fontWeight: 'bold' }}>Party Name</span></TableCell>
                                    <TableCell align="left"><span style={{ fontWeight: 'bold' }}>Total Amount</span></TableCell>
                                    <TableCell align="left"><span style={{ fontWeight: 'bold' }}>Actions</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredLedgerItems.map(party => (
                                    <TableRow key={party._id}>
                                        <TableCell>{party.partyName}</TableCell>
                                        <TableCell align="left">{getTotalAmount(party.items)}</TableCell>
                                        <TableCell align="left">
                                            <IconButton onClick={() => handleEditOpen(party)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleLedgerHistory(party.partyName)}>
                                                <InfoIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleRefundHistory(party._id,party.partyName)}>
                                                <PaymentsOutlined />
                                            </IconButton>
                                            <IconButton onClick={() => handleRefundHistoryOpenModal(party._id)}>
                                                <HistoryOutlined />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteParty(party._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Add Ledger Modal */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="add-ledger-modal-title"
                    aria-describedby="add-ledger-modal-description"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', sm: 600 },
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}>
                            <Typography variant="h6" gutterBottom>Add Ledger</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                <TextField
                                    label="Party Name *"
                                    value={partyName}
                                    onChange={(e) => setPartyName(e.target.value)}
                                    fullWidth
                                    placeholder='Party Name'
                                    sx={responsiveTextFieldStyle}

                                    margin="dense"
                                    
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    {items.map((item, index) => (
                                        <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                            <TextField
                                                label="Item Name *"
                                                value={item.itemName}
                                                onChange={(e) => handleUpdateItem(index, 'itemName', e.target.value)}
                                                fullWidth
                                                sx={responsiveTextFieldStyle}

                                                placeholder='Item Name'
                                                margin="dense"
                                            />
                                            <TextField
                                                label="IMEI *"
                                                value={item.IMEI}
                                                onChange={(e) => handleUpdateItem(index, 'IMEI', e.target.value)}
                                                fullWidth
                                                type={"text"}
                                                sx={responsiveTextFieldStyle}

                                                placeholder='IMEI'
                                                margin="dense"
                                            />
                                            <TextField
                                                label="Amount *"
                                                value={item.amount}
                                                onChange={(e) => handleUpdateItem(index, 'amount', e.target.value)}
                                                fullWidth
                                                type={"number"}
                                                sx={responsiveTextFieldStyle}

                                                placeholder='Item Amount'
                                                margin="dense"
                                            />
                                          
                                            <TextField
                                                        label="Date*"
                                                        type="date"
                                                        fullWidth
                                                        value={item.date}
                                                        onChange={(date) => handleUpdateItem(index, 'date', date)}
                                                        format="dd.MM.yyyy"
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{ inputProps: { max: today } }} // Restrict date selection to today or earlier
                                                        variant="outlined"
                                                    />
                                        </Box>
                                    ))}
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={handleAddAnotherItem}>
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            <Button variant="contained" color="primary" onClick={handleAddLedger} sx={{ marginTop: '10px' }}>
                                Add Ledger
                            </Button>
                        </Box>
                    </Fade>
                </Modal>

                {/* Edit Ledger Modal */}
                <Modal
                    open={editOpen}
                    onClose={handleEditClose}
                    aria-labelledby="edit-item-modal-title"
                    aria-describedby="edit-item-modal-description"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade in={editOpen}>
                        <Box sx={modalStyle}>
                            <Typography variant="h6" gutterBottom>
                                Edit Items for Party
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                <TextField
                                    label="Party Name"
                                    value={partyName}
                                    onChange={(e) => setPartyName(e.target.value)}
                                    fullWidth
                                    placeholder='Party Name'
                                    margin="dense"
                                    sx={{ marginBottom: '10px' }}
                                />
                                <Box sx={scrollableContainerStyle}>
                                    {items.map((item, index) => (
                                        <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: '10px' }}>
                                            <TextField
                                                label="Item Name *"
                                                value={item.itemName}
                                                onChange={(e) => handleUpdateItem(index, 'itemName', e.target.value)}
                                                fullWidth
                                                placeholder='Item Name'
                                                margin="dense"
                                            />
                                            <TextField
                                                label="IMEI *"
                                                value={item.IMEI}
                                                onChange={(e) => handleUpdateItem(index, 'IMEI', e.target.value)}
                                                type="text"
                                                fullWidth
                                                placeholder='Item Name'
                                                margin="dense"
                                            />
                                            <TextField
                                                label="Amount *"
                                                value={item.amount}
                                                onChange={(e) => handleUpdateItem(index, 'amount', e.target.value)}
                                                fullWidth
                                                placeholder='Item Amount'
                                                type={"number"}
                                                margin="dense"
                                            />
                                            <DatePicker
                                                label={'Date: *'}
                                                value={item.date}
                                                onChange={(date) => handleUpdateItem(index, 'date', date)}
                                                format="dd.MM.yyyy"
                                                oneTap
                                                style={{ width: '100%' }}
                                            />
                                            <IconButton onClick={() => handleDeleteItem(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton onClick={handleAddAnotherItem}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Button variant="contained" color="primary" onClick={handleAddItem} sx={{ marginTop: '10px' }}>
                                Update Items
                            </Button>
                        </Box>
                    </Fade>
                </Modal>



            </Box>
            <LedgerHistory
                open={historyOpen}
                handleClose={() => setHistoryOpen(false)}
                items={historyItems}
            />

            <RefundModal
                open={RefundHistory}
                handleClose={() => setRefundHistory(false)}
                onSubmit={handleRefundSubmit}
                partyId={partyId}
                partyName={partname}

            />

            <RefundHistoryModal
                open={modalOpen}
                handleClose={handleRefundHistoryCloseModal}
                refundData={refundData}
            />
        </>

    );

}
