import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar, Toolbar, Typography, Container, Button, Box, Paper, Grid, TextField, Tabs, Tab, List, ListItem, ListItemText
} from '@mui/material';
import NavBar from '../Components/Appbar';
import SectionContainer from '../Components/SectionContainer';
import baseurl from '../Assets/baseurl';
import {jwtDecode} from 'jwt-decode';

export default function Bank() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <NavBar />
      <SectionContainer title={'My Bank'} showFilter={false} showSearch={false} filter={false} onFilterChange={false} />
      <Container>
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="Balance" />
          <Tab label="History" />
          <Tab label="Transfers" />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <Accounts />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Transactions />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <Transfers />
        </TabPanel>
      </Container>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
function Accounts() {
  const [balance, setBalance] = useState(null);
  const token = localStorage.getItem('token');
    const verifyToken = jwtDecode(token);
    const email=verifyToken.email;

    useEffect(() => {
      const fetchBalance = async () => {
        try {
          const response = await axios.get(`${baseurl}/bank/balance/${email}`);
          const totalBalance = response.data.totalBalance;
    
          if (typeof totalBalance === 'number') {
            setBalance(totalBalance);
          } else {
            console.error('Invalid balance received:', totalBalance);
            setBalance(0); // Set a default value in case of unexpected response
          }
        } catch (error) {
          console.error('Error fetching balance:', error);
        }
      };
    
      fetchBalance();
    }, []);
    


  return (
    <Box>
      <Typography variant="h6">Total Balance:</Typography>
      <Typography variant="h4">Rs {typeof balance === 'number' ? balance.toFixed(2) : 'Loading...'}</Typography>

    </Box>
  );
  
}


function Transactions() {
  const [filter, setFilter] = useState('All');
  const [transactions, setTransactions] = useState([]); // Initialize as an empty array
  const token = localStorage.getItem('token');
  const verifyToken = jwtDecode(token);
  const email = verifyToken.email;
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
          const response = await axios.get(`${baseurl}/bank/transactions/${email}`);
          console.log(response.data)
          const deposits = response.data.deposits || [];
          const withdrawals = response.data.withdrawals || [];
  
          const combinedTransactions = [
              ...deposits.map(transaction => ({
                  _id: transaction._id,
                  amount: transaction.amount,
                  date: transaction.date,
                  description: transaction.description,
                  type: 'Bank',
                  credited: 0, // Deposit means money is debited from the account
                  debited: transaction.amount,
              })),
              ...withdrawals.map(transaction => ({
                  _id: transaction._id,
                  amount: transaction.amount,
                  date: transaction.date,
                  description: transaction.description,
                  type: 'Bank',
                  credited: transaction.amount, // Withdraw means money is credited to the account
                  debited: 0,
              }))
          ];
  
          setTransactions(combinedTransactions);
      } catch (error) {
          console.error('Error fetching transactions:', error);
      }
  };
  

    fetchTransactions();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const filteredTransactions = (Array.isArray(transactions) ? transactions : []).filter(transaction =>
    filter === 'All' ? true : transaction.type === filter
  );

  return (
    <Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Button variant={filter === 'All' ? 'contained' : 'outlined'} onClick={() => setFilter('All')}>All</Button>
        <Button variant={filter === 'Deposit' ? 'contained' : 'outlined'} onClick={() => setFilter('Deposit')}>Deposit</Button>
        <Button variant={filter === 'Withdraw' ? 'contained' : 'outlined'} onClick={() => setFilter('Withdraw')}>Withdraw</Button>
      </Box>
      <List>
        {filteredTransactions.map(transaction => (
          <ListItem key={transaction._id}>
            <ListItemText
              primary={`${transaction.type}: Rs. ${transaction.amount.toFixed(2)}`}
              secondary={`${transaction.description} \r${formatDate(transaction.date)}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
function Transfers() {
  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('Deposit');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [currentBalance, setCurrentBalance] = useState(null);
  const token = localStorage.getItem('token');
  const verifyToken = jwtDecode(token);
  const email = verifyToken.email;

  const fetchBalance = async () => {
    try {
      const response = await axios.get(`${baseurl}/bank/balance/${email}`);
      setCurrentBalance(response.data.totalBalance);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const handleTransfer = async () => {
    const parsedAmount = parseFloat(amount);

    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      console.error('Invalid amount');
      alert('Please enter a valid amount greater than zero.');
      return;
    }

    if (transactionType === 'Withdraw' && currentBalance < parsedAmount) {
      console.error('Insufficient funds');
      alert('Insufficient funds for this withdrawal');
      return;
    }

    const transactionData = {
      type: transactionType,
      amount: parsedAmount,
      description: transactionType === 'Withdraw' ? 'Money Withdrawn' : 'Money Deposited',
      email,
      date,
    };

    try {
      const response = await axios.post(`${baseurl}/bank/transactions`, transactionData);
      console.log('Transaction successful:', response.data);
      fetchBalance(); // Update balance after successful transaction
    } catch (error) {
      console.error('Error creating transaction:', error);
    }
  };
  const today = new Date().toISOString().split('T')[0];

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Typography variant="h6" gutterBottom>Transfer Money</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            label="Amount"
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            variant="outlined"
            inputProps={{ min: "0" }} // Prevent negative input
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date"
            type="date"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { max: today } }} // Restrict date selection to today or earlier

            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={() => setTransactionType('Deposit')}
            color={transactionType === 'Deposit' ? 'primary' : 'inherit'}
            sx={{ marginRight: 2 }}
          >
            Deposit
          </Button>
          <Button
            variant="contained"
            onClick={() => setTransactionType('Withdraw')}
            color={transactionType === 'Withdraw' ? 'primary' : 'inherit'}
          >
            Withdraw
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleTransfer}
          disabled={!amount || isNaN(amount) || !email}
        >
          Submit
        </Button>
      </Box>
    </Paper>
  );
}
