import React, { useEffect, useState } from 'react';
import NavBar from '../Components/Appbar';
import { Grid, Button, Modal, Backdrop, Fade, Box, Typography, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ExpenseCard from '../Components/ExpenseCard';
import baseURL from '../Assets/baseurl';
import DatePicker from "rsuite/DatePicker";


export default function Expense() {
  const [data, setData] = useState({ today: [], weekly: [], monthly: [] });
  const [filter, setFilter] = useState('today');
  const [render, setRender] = useState(0);
  const [totals, setTotals] = useState({ today: 0, weekly: 0, monthly: 0 });

  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [desc, setDesc] = useState('');

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateId, setUpdateId] = useState('');
  const [updateAmount, setUpdateAmount] = useState(0);
  const [updateDesc, setUpdateDesc] = useState('');

  useEffect(() => {
    fetchData();
    fetchTotals();
  }, [render]);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    let today = [], weekly = [], monthly = [];

    try {
      const todayResponse = await axios.get(`${baseURL}/expense/getlisttodayExpense`, {
        headers: { authorization: `token ${token}` }
      });
      today = todayResponse.data;
    } catch (e) {
      console.log('Error listing today\'s expense');
    }

    try {
      const weeklyResponse = await axios.get(`${baseURL}/expense/getlistWeeklyExpense`, {
        headers: { authorization: `token ${token}` }
      });
      weekly = weeklyResponse.data;
    } catch (e) {
      console.log('Error listing weekly expense');
    }

    try {
      const monthlyResponse = await axios.get(`${baseURL}/expense/getlistMonthlyExpense`, {
        headers: { authorization: `token ${token}` }
      });
      monthly = monthlyResponse.data;
    } catch (e) {
      console.log('Error listing monthly expense');
    }

    setData({ today, weekly, monthly });
  };

  const fetchTotals = async () => {
    const token = localStorage.getItem('token');
    let today = 0, weekly = 0, monthly = 0;

    try {
      const todayTotal = await axios.get(`${baseURL}/expense/getTodayExpense`, {
        headers: { authorization: `token ${token}` }
      });
      today = todayTotal.data.total;
    } catch (e) {
      console.log('Error fetching today\'s total expense');
    }

    try {
      const weeklyTotal = await axios.get(`${baseURL}/expense/getWeeklyExpense`, {
        headers: { authorization: `token ${token}` }
      });
      weekly = weeklyTotal.data.total;
    } catch (e) {
      console.log("Error fetching weekly total expense");
    }

    try {
      const monthlyTotal = await axios.get(`${baseURL}/expense/getMonthlyExpense`, {
        headers: { authorization: `token ${token}` }
      });
      monthly = monthlyTotal.data.total;
    } catch (e) {
      console.log("Error fetching monthly total expense");
    }

    setTotals({ today, weekly, monthly });
  };

  const AddExpense = async (amount, description) => {
    const token = localStorage.getItem('token');
    if (description !== '' && amount > 0) {
      await axios.post(`${baseURL}/expense/add`, { amount, description,selectedDate }, {
        headers: { authorization: `token ${token}` }
      });
      setAmount(0);
      setDesc('');
      setRender(render + 1);
    }
  };

  const DeleteExpense = async (id) => {
    const token = localStorage.getItem('token');
    await axios.delete(`${baseURL}/expense/delete/${id}`, {
      headers: { authorization: `token ${token}` }
    });
    setRender(render + 1);
  };

  const updateExpense = async () => {
    const token = localStorage.getItem('token');
    if (updateDesc !== '' && updateAmount > 0) {
      await axios.put(`${baseURL}/expense/update/${updateId}`, { amount: updateAmount, description: updateDesc }, {
        headers: { authorization: `token ${token}` }
      });
      setRender(render + 1);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleAddExpense = () => { AddExpense(amount, desc); handleClose(); };
  const handleChange = (event) => setFilter(event.target.value);
  const handleOpenUpdateModal = (id, amount, desc) => { setUpdateId(id); setUpdateAmount(amount); setUpdateDesc(desc); setOpenUpdateModal(true); };
  const handleCloseUpdateModal = () => setOpenUpdateModal(false);
  const handleUpdateExpense = () => { updateExpense(); handleCloseUpdateModal(); };

  const calculateTotal = () => filter === 'weekly' ? totals.weekly : filter === 'monthly' ? totals.monthly : totals.today;

  const showDataInTable = () => {
    const temp = (arr) => arr.map((val, index) => (
        <TableRow key={val._id}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>{val.description}</TableCell>
          <TableCell>Rs.{val.amount}</TableCell>
          <TableCell>{new Date(val.timestamp).toDateString()}</TableCell>
          <TableCell>
            <Button variant="contained" color="success" startIcon={<SyncAltIcon />} sx={{ mr: 1 }} onClick={() => handleOpenUpdateModal(val._id, val.amount, val.description)}>
              Update
            </Button>
            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => DeleteExpense(val._id)}>
              Delete
            </Button>
          </TableCell>
        </TableRow>
    ));
    return filter === 'weekly' ? temp(data.weekly) : filter === 'monthly' ? temp(data.monthly) : temp(data.today);
  };

  const handleDateChange = (date) => setSelectedDate(date);

  return (
      <>
        <NavBar />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
          <div style={{ width: '80%' }}>
            <Typography variant="h4" gutterBottom>My Expenses</Typography>
            <Box display="flex" mt={4} mb={4} gap={4}>
              <ExpenseCard expenses={data.today.slice(0, 5)} title={'Today\'s Expenses'} total={totals.today} />
              <ExpenseCard expenses={data.weekly.slice(0, 5)} title={'Weekly Expenses'} total={totals.weekly} />
              <ExpenseCard expenses={data.monthly.slice(0, 5)} title={'Monthly Expenses'} total={totals.monthly} />
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Add Today Expense
              </Button>
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="select-label">Show Expenses</InputLabel>
                <Select labelId="select-label" id="simple-select" value={filter} label="Age" onChange={handleChange}>
                  <MenuItem value={'today'}>Today's Expenses</MenuItem>
                  <MenuItem value={'weekly'}>Weekly Expenses</MenuItem>
                  <MenuItem value={'monthly'}>Monthly Expenses</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showDataInTable()}
                </TableBody>
              </Table>
            </TableContainer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="expense-modal-title"
                aria-describedby="expense-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={open}>
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2, // Rounded corners for a modern look
                  maxWidth: '90vw', // Ensure responsiveness on smaller screens
                }}>
                  <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', color: 'primary.main' }}>
                    Add Today's Expense
                  </Typography>

                  <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <TextField
                        label="Description"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        fullWidth
                        placeholder="Expenditure Name"
                        margin="dense"
                        variant="outlined" // Ensure consistent input styling
                        autoFocus
                    />
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        style={{padding: '8px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '4px'}}
                    />
                    <TextField
                        label="Amount"
                        value={amount}
                        type="number"
                        placeholder="Amount Rs"
                        onChange={(e) => setAmount(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                    />
                  </Box>

                  <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddExpense}
                      sx={{mt: 3, width: '100%'}}
                  >
                  Add Expense
                  </Button>
                </Box>
              </Fade>
            </Modal>


            <Modal
                open={openUpdateModal}
                onClose={handleCloseUpdateModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
              <Fade in={openUpdateModal}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4, bgcolor: 'background.paper', boxShadow: 24, borderRadius: 1, width: 400 }}>
                  <Typography variant="h6" gutterBottom>Update Expense</Typography>
                  <TextField fullWidth variant="outlined" label="Amount" type="number" value={updateAmount} onChange={(e) => setUpdateAmount(e.target.value)} sx={{ mb: 2 }} />
                  <TextField fullWidth variant="outlined" label="Description" multiline rows={4} value={updateDesc} onChange={(e) => setUpdateDesc(e.target.value)} sx={{ mb: 2 }} />
                  <Button variant="contained" color="primary" onClick={handleUpdateExpense}>Update Expense</Button>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      </>
  );
}
