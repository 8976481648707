import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import baseurl from "../Assets/baseurl";
import {jwtDecode} from "jwt-decode";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const RefundModal = ({ open, handleClose, onSubmit, partyId, partyName }) => {
    const [refundAmount, setRefundAmount] = useState('');
    const [refundDate, setRefundDate] = useState(new Date().getDate("dd-MM-YYYY"));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleAmountChange = (e) => {
        setRefundAmount(e.target.value);
    };

    const handleDateChange = (e) => {
        setRefundDate(e.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');
        const verifyToken = jwtDecode(token);
        const email = verifyToken.email;

        try {
            await axios.post(`${baseurl}/ledger/refund`, {
                email,
                partyId,
                partyName,
                refundAmount: Number(refundAmount),
                refundDate,
            });
            onSubmit({ refundAmount, refundDate }); // Optional: Call onSubmit to handle any additional logic
            handleClose(); // Close the modal on successful submission
        } catch (err) {
            console.error('Error submitting refund:', err);
            setError('Failed to submit refund.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="refund-modal-title"
            aria-describedby="refund-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="refund-modal-title" variant="h5" component="h2">
                    Process Refund for {partyName}
                </Typography>
                <Box component="form" sx={{ mt: 2 }}>
                    <TextField
                        label="Refund Amount *"
                        variant="outlined"
                        fullWidth
                        value={refundAmount}
                        onChange={handleAmountChange}
                        margin="normal"
                    />
                    <TextField
                        label="Date *"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={refundDate}
                        onChange={handleDateChange}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                            sx={{ ml: 2 }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default RefundModal;
