import React, { useState, useEffect } from "react";
import Appbar from "../Components/Appbar";
import SectionContainer from "../Components/SectionContainer";
import { jwtDecode } from 'jwt-decode';
import {
    Backdrop,
    Box,
    Button,
    Fade,
    Modal,
    TextField,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton
} from "@mui/material";
import DatePicker from "rsuite/DatePicker";
import "rsuite/dist/rsuite.min.css";
import CreditBuyCard from "../Components/CreditBuyCard";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import baseurl from "../Assets/baseurl";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import CreditBuyHistory from "../Components/CreditBuyHistory";
import { HistoryOutlined, PaymentsOutlined } from "@mui/icons-material";
import RefundModal from "../Components/RefundModal";
import RefundHistoryModal from "../Components/RefundHistoryModal";
import CreditRefundModal from "../Components/CreditRefundModal";

export default function ManageCreditBuys() {
    const [creditBuyItems, setCreditBuyItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [currentPartyId, setCurrentPartyId] = useState(null);
    const [partyName, setPartyName] = useState('');
    const [items, setItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [historyOpen, setHistoryOpen] = useState(false);
    const [RefundHistory, setRefundHistory] = useState(false);
    const [historyItems, setHistoryItems] = useState([]);
    const [RefundmodalOpen, setRefundModalOpen] = useState(false);
    const [refundData, setRefundData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [partyId, setPartyId] = useState(null);
    const [partname, setPartname] = useState(null);

    // Fetch credit buy items from the backend
    useEffect(() => {
        fetchCreditBuyItems();
    }, []);

    const fetchCreditBuyItems = async () => {
        try {

            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;
console.log(email)
            const response = await axios.get(`${baseurl}/credit-buy/credit-buy/${email}`, {
                headers: {
                    // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                }
            });
console.log(response.data)
            if (response.status === 200) {
                const data = response.data;
                setCreditBuyItems(data.parties || []);
            } else {
                console.error('Failed to fetch credit buy items');
            }
        } catch (error) {
            console.error('Error fetching credit buy items:', error);
        }
    };

    // Handle opening the "Add Credit Buy" modal
    const handleOpen = () => {
        setOpen(true);
    };

    // Handle closing the "Add Credit Buy" modal
    const handleClose = () => {
        setOpen(false);
    };

    const handleRefundCloseModal = () => {
        setRefundHistory(false);
    };

    // Handle opening the "Edit Credit Buy" modal
    const handleEditOpen = (party) => {
        setCurrentPartyId(party._id);
        setPartyName(party.partyName);
        setItems(party.items);
        setEditOpen(true);
    };

    // Handle closing the "Edit Credit Buy" modal
    const handleEditClose = () => {
        setEditOpen(false);
    };

    // Add a new credit buy or update existing party
    const handleAddCreditBuy = async () => {
        try {
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;

            const response = await axios.post(`${baseurl}/credit-buy/credit-buy`, {
                email,
                partyName,
                items
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                }
            });

            if (response.status === 200 || response.status === 201) { // Assuming 201 for created
                fetchCreditBuyItems(); // Refresh the list
                setPartyName('');
                setItems([]);
                handleClose();
            } else {
                console.error('Failed to add or update credit buy');
            }
        } catch (error) {
            console.error('Error adding or updating credit buy:', error);
        }
    };

    // Update the items list in a credit buy
    const handleAddItem = async () => {
        try {
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;

            const response = await axios.put(`${baseurl}/credit-buy/credit-buy/${email}/${partyName}`, {
                items
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                }
            });

            if (response.status === 200) {
                fetchCreditBuyItems(); // Refresh the list
                setPartyName('');
                setItems([]);
                handleEditClose();
            } else {
                console.error('Failed to update items');
            }
        } catch (error) {
            console.error('Error updating items:', error);
        }
    };

    // Update specific item fields
    const handleUpdateItem = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index] = { ...updatedItems[index], [field]: value };
        setItems(updatedItems);
    };

    // Add another item to the items list
    const handleAddAnotherItem = () => {
        setItems([...items, { itemName: '', IMEI: '', amount: '', date: new Date() }]);
    };

    // Delete an item from the items list
    const handleDeleteItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    // Credit Buy History of A Party
    const handleCreditBuyHistory = async (partyName) => {
        try {
            const token = localStorage.getItem('token');
            const verifyToken = jwtDecode(token);
            const email = verifyToken.email;

            const response = await axios.post(`${baseurl}/credit-buy/getPartyItem`, {
                email,
                partyName
            });

            if (response.status === 200) {
                console.log('Fetched history data:', response.data.items); // Log the received data
                setHistoryItems(response.data.items || []);
                setHistoryOpen(true);
            } else {
                console.error('Failed to fetch history');
            }
        } catch (error) {
            console.error('Error fetching history:', error);
        }
    };

    const handleRefundHistoryOpenModal = async (partyId) => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`${baseurl}/credit-buy/refund-history/${partyId}`);
            setRefundData(response.data);
            setRefundModalOpen(true);
        } catch (err) {
            console.error("Error fetching refund history:", err);
            setError("Failed to fetch refund history.");
        } finally {
            setLoading(false);
        }
    };

    const handleRefundHistoryCloseModal = () => {
        setRefundModalOpen(false);
        setHistoryOpen(false)
    };

    const handleRefundHistory = async (partId, partName) => {
        setPartyId(partId);
        setPartname(partName);
        setRefundHistory(true);
    };

    const handleRefundSubmit = async () => {
        window.location.reload();
    };

    // Delete a party from the credit buys
    const handleDeleteParty = async (partyId) => {
        const confirmed = window.confirm("Are you sure you want to delete this party?");
        if (confirmed) {
            try {
                const token = localStorage.getItem('token');
                const verifyToken = jwtDecode(token);
                const email = verifyToken.email;

                const response = await axios.delete(`${baseurl}/credit-buy/credit-buy/${email}/${partyId}`, {
                    headers: {
                        // 'Authorization': `Bearer ${token}` // Include the token in the headers if needed
                    }
                });

                if (response.status === 200) {
                    fetchCreditBuyItems(); // Refresh the list
                } else {
                    console.error('Failed to delete party');
                }
            } catch (error) {
                console.error('Error deleting party:', error);
            }
        }
    };

    // Calculate total amount for items
    const getTotalAmount = (items) => items.reduce((total, item) => total + parseFloat(item.amount || 0), 0);

    // Filter credit buy items based on search query
    const filteredCreditBuyItems = creditBuyItems.filter(party =>
        party.partyName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh',
        overflowY: 'auto'
    };

    return (
        <>
            <Appbar />
            <SectionContainer title={"Manage My Credit"} showFilter={false} showSearch={false} filter={false} onFilterChange={false}/>

                <TextField
                    label="Search by Party Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    startIcon={<AddIcon />}
                >
                    Add Credit Buy
                </Button>
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Party Name</TableCell>
                                <TableCell>Items Count</TableCell>
                                <TableCell>Total Amount</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredCreditBuyItems.map((party) => (
                                <TableRow key={party._id}>
                                    <TableCell>{party.partyName}</TableCell>
                                    <TableCell>{party.items.length}</TableCell>
                                    <TableCell>{getTotalAmount(party.items)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditOpen(party)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteParty(party._id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleCreditBuyHistory(party.partyName)}>
                                            <HistoryOutlined />
                                        </IconButton>
                                        <IconButton onClick={() => handleRefundHistory(party._id, party.partyName)}>
                                            <PaymentsOutlined />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Add Credit Buy
                        </Typography>
                        <TextField
                            label="Party Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={partyName}
                            onChange={(e) => setPartyName(e.target.value)}
                        />
                        {items.map((item, index) => (
                            <div key={index}>
                                <TextField
                                    label="Item Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={item.itemName}
                                    onChange={(e) => handleUpdateItem(index, 'itemName', e.target.value)}
                                />
                                <TextField
                                    label="IMEI"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={item.IMEI}
                                    onChange={(e) => handleUpdateItem(index, 'IMEI', e.target.value)}
                                />
                                <TextField
                                    label="Amount"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={item.amount}
                                    onChange={(e) => handleUpdateItem(index, 'amount', e.target.value)}
                                />
                                <DatePicker
                                    oneTap
                                    style={{ width: "100%", marginBottom: "16px" }}
                                    placeholder="Select Date"
                                    value={item.date}
                                    onChange={(date) => handleUpdateItem(index, 'date', date)}
                                />
                                <IconButton onClick={() => handleDeleteItem(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        ))}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddAnotherItem}
                            startIcon={<AddIcon />}
                            style={{ marginTop: "16px" }}
                        >
                            Add Another Item
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddCreditBuy}
                            style={{ marginTop: "16px" }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                open={editOpen}
                onClose={handleEditClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={editOpen}>
                    <Box sx={modalStyle}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Edit Credit Buy
                        </Typography>
                        <TextField
                            label="Party Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={partyName}
                            onChange={(e) => setPartyName(e.target.value)}
                        />
                        {items.map((item, index) => (
                            <div key={index}>
                                <TextField
                                    label="Item Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={item.itemName}
                                    onChange={(e) => handleUpdateItem(index, 'itemName', e.target.value)}
                                />
                                <TextField
                                    label="IMEI"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={item.IMEI}
                                    onChange={(e) => handleUpdateItem(index, 'IMEI', e.target.value)}
                                />
                                <TextField
                                    label="Amount"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={item.amount}
                                    onChange={(e) => handleUpdateItem(index, 'amount', e.target.value)}
                                />
                                <DatePicker
                                    oneTap
                                    style={{ width: "100%", marginBottom: "16px" }}
                                    placeholder="Select Date"
                                    value={item.date}
                                    onChange={(date) => handleUpdateItem(index, 'date', date)}
                                />
                                <IconButton onClick={() => handleDeleteItem(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        ))}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddAnotherItem}
                            startIcon={<AddIcon />}
                            style={{ marginTop: "16px" }}
                        >
                            Add Another Item
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddItem}
                            style={{ marginTop: "16px" }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Fade>
            </Modal>
            <CreditBuyHistory

                open={historyOpen}
                handleClose={() => setHistoryOpen(false)}
                historyItems={historyItems}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            />
            <CreditRefundModal
                open={RefundmodalOpen}
                handleClose={handleRefundHistoryCloseModal}
                partyId={partyId}
                partyName={partname}
                historyItems={refundData}
                loading={loading}
                error={error}
                onSubmit={handleRefundSubmit}
            />
            <CreditRefundModal
                open={RefundHistory}
                handleClose={handleRefundCloseModal}
                partyId={partyId}
                partyName={partname}
            />
        </>
    );
}
