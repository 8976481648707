import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    Backdrop,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const scrollableTableContainer = {
    maxHeight: '400px', // Set the maximum height of the table container
    overflowY: 'auto'   // Enable vertical scrolling
};

const CreditBuyHistory = ({ open, handleClose, historyItems }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (Array.isArray(historyItems)) {
            const filtered = historyItems.filter((item) =>
                item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) || item.IMEI.toLowerCase().includes(searchTerm.toLowerCase())
            );

            // Sort items by date in descending order
            const sorted = filtered.sort((a, b) => new Date(b.date) - new Date(a.date));

            setFilteredItems(sorted);
        } else {
            setFilteredItems([]);
        }
    }, [searchTerm, historyItems]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="history-modal-title"
            aria-describedby="history-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Box sx={modalStyle}>
                <Typography id="history-modal-title" variant="h6" component="h2">
                    Item History
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                    />
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </Box>
                <Box sx={scrollableTableContainer}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bolder" }} align="left">Item Name</TableCell>
                                    <TableCell sx={{ fontWeight: "bolder" }} align="left">IMEI</TableCell>
                                    <TableCell sx={{ fontWeight: "bolder" }} align="right">Amount</TableCell>
                                    <TableCell sx={{ fontWeight: "bolder" }} align="right">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredItems.map((item) => (
                                    <TableRow key={item._id}>
                                        <TableCell component="th" scope="row">
                                            {item.itemName}
                                        </TableCell>
                                        <TableCell align="left">{item.IMEI}</TableCell>
                                        <TableCell align="right">{item.amount}</TableCell>
                                        <TableCell align="right">{new Date(item.date).toLocaleDateString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreditBuyHistory;
