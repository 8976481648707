import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: "Helvetica",
    color: "#333",
  },
  section: {
    marginBottom: 10,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
    color: "#4CAF50",
  },
  table: {
    display: "table",
    width: "auto",
    marginTop: 20,
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    textAlign: "left",
    borderBottom: "1 solid black",
    fontWeight: "bold",
    padding: 5,
    backgroundColor: "#f2f2f2",
  },
  tableCol: {
    width: "25%",
    textAlign: "left",
    borderBottom: "1 solid gray",
    padding: 5,
  },
  footer: {
    textAlign: "center",
    marginTop: 20,
    borderTop: "1 solid #333",
    paddingTop: 10,
  },
});

const InvoiceDocument = ({ data,invoiceNo }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>INVOICE</Text>
        <View>
          <Text>Date: {data.date}</Text>
          <Text>{invoiceNo}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={{ fontWeight: "bold" }}>BILL TO:</Text>
        <Text>{data.billTo.name}</Text>
        <Text>{data.billTo.phoneNumber}</Text>
        <Text>{data.billTo.address}</Text>
      </View>
      <View style={styles.section}>
        <Text style={{ fontWeight: "bold" }}>FROM:</Text>
        <Text>{data.from.name}</Text>
        <Text>{data.from.phoneNumber}</Text>
        <Text>{data.from.address}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColHeader}>DESCRIPTION</Text>
          <Text style={styles.tableColHeader}>IMEI</Text>
          <Text style={styles.tableColHeader}>PRICE</Text>
          <Text style={styles.tableColHeader}>TOTAL</Text>
        </View>
        {data.items.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={styles.tableCol}>{item.description}</Text>
            <Text style={styles.tableCol}>{item.imei}</Text>
            <Text style={styles.tableCol}>{item.price.toLocaleString()}</Text>
            <Text style={styles.tableCol}>{item.total.toLocaleString()}</Text>
          </View>
        ))}
      </View>
      {data.exchangeItems && data.exchangeItems.length > 0 && (
        <View style={styles.section}>
          <Text style={{ fontWeight: "bold" }}>EXCHANGE ITEMS:</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>DESCRIPTION</Text>
              <Text style={styles.tableColHeader}>IMEI</Text>
              <Text style={styles.tableColHeader}>PRICE</Text>
              <Text style={styles.tableColHeader}>TOTAL</Text>
            </View>
            {data.exchangeItems.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCol}>{item.description}</Text>
                <Text style={styles.tableCol}>{item.imei}</Text>
                <Text style={styles.tableCol}>{item.price.toLocaleString()}</Text>
                <Text style={styles.tableCol}>{item.total.toLocaleString()}</Text>
              </View>
            ))}
          </View>
        </View>
      )}
      <Text style={{ fontWeight: "bold", marginTop: 10 }}>Total amount: Rs {data.totalAmount.toLocaleString()}</Text>
      <View style={styles.footer}>
        <Text>Thank you for your business!</Text>
        <Text>Generated By InvenCell, Powered By XAppifai</Text>
      </View>
    </Page>
  </Document>
);

const generatePDF = async (invoiceData, fileName) => {
  const blob = await pdf(<InvoiceDocument data={invoiceData} invoiceNo={fileName} />).toBlob();
  saveAs(blob, `${fileName}.pdf`);
};

export default generatePDF;
