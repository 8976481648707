import React, { useEffect, useState } from 'react';

import { Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';

import {jwtDecode} from "jwt-decode";
import baseurl from "../Assets/baseurl";

export default function LedgerCard() {
    const [pendingTotal, setPendingTotal] = useState(0);

    useEffect(() => {
        fetchPendingTotal();
    }, []);

    const fetchPendingTotal = async () => {
        const token = localStorage.getItem('token');
        const verifyToken = jwtDecode(token);
        const email = verifyToken.email; // replace with the actual email

        try {
            const response = await axios.get(`${baseurl}/ledger/ledger/${email}` );
            const data = response.data;
            let total = 0;

            data.parties.forEach(party => {
                party.items.forEach(item => {
                    total += item.amount;
                });
            });

            setPendingTotal(total);
        } catch (e) {
            console.log('Error fetching pending total amount');
        }
    };

    return (
        <div>

            <Card
                style={{
                    height: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    aspectRatio: '1 / 1',
                    margin: '8px',
                    width: '100%',
                }}
                sx={{ boxShadow: 3, padding: '20px' }}
            >
                <CardContent style={{ overflowY: 'auto', maxHeight: '200px' }}>
                    <Typography variant="h7" component="div" sx={{ fontWeight: 'bolder', my: '10px',fontSize:"18px" }}>
                        Total Pending Payment:
                    </Typography>

                    <Typography variant="h5" component="div" style={{ fontWeight: 'bolder', textAlign: 'center', marginTop: '20px' }}>
                        Rs. {pendingTotal}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}
