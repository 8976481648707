import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const scrollableTableContainer = {
    maxHeight: '400px', // Set the maximum height of the table container
    overflowY: 'auto'   // Enable vertical scrolling
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Customize the format if needed
};

const RefundHistoryModal = ({ open, handleClose, refundData }) => {
    const { partyName, history } = refundData || {};

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="refund-history-modal-title"
            aria-describedby="refund-history-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="refund-history-modal-title" variant="h5" component="h2">
                    Refund History for {partyName}
                </Typography>
                <Box sx={scrollableTableContainer}>
                    <TableContainer component={Paper}>
                        <Table aria-label="refund history table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bolder" }} align="left">Refund Amount</TableCell>
                                    <TableCell sx={{ fontWeight: "bolder" }} align="left">Refund Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {history && history.length > 0 ? (
                                    history.map((record) => (
                                        <TableRow key={record._id}>
                                            <TableCell component="th" scope="row">
                                                Rs. {record.refundAmount}
                                            </TableCell>
                                            <TableCell align="left">
                                                {formatDate(record.refundDate)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2} align="center">
                                            No refund history available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Modal>
    );
};

export default RefundHistoryModal;
