import React, { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import NavBar from '../Components/Appbar';
import { Box, TextField, Button, Typography, Select, MenuItem, Divider, InputLabel, FormControl, Grid, Snackbar, RadioGroup, FormControlLabel, Radio, Autocomplete } from '@mui/material';
import DatePicker from 'rsuite/DatePicker';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import 'rsuite/DatePicker/styles/index.css';
import baseURL from '../Assets/baseurl';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddStock() {
  const [password, setPassword] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [CustomerName, setCustomerName] = useState('');
  const [CustomerCNIC, setCustomerCNIC] = useState('');
  const [CustomerAddress, setCustomerAddress] = useState('');
  const [CustomerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [BrandName, setBrand] = useState('');
  const [IMEIList, setIMEIList] = useState([]);
  const [Model, setModel] = useState('');
  const [Color, setColor] = useState([]);
  const [NetworkStatus, setNetworkStatus] = useState('');
  const [Storage, setStorage] = useState([]);
  const [Price, setPrice] = useState('');
  const [brandModels, setBrandModels] = useState({});
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [parties, setParties] = useState([]);
  const [useSavedParty, setUseSavedParty] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [selectedParty, setSelectedParty] = useState(parties[0] || null);
  const [inputValue, setInputValue] = useState('');




  useEffect(() => {
    axios.get(`${baseURL}/mobile/mobile`)
        .then(response => {
          const fetchedData = response.data;
          const transformedData = fetchedData.reduce((acc, item) => {
            const { brand, model, colors = [], storage = [] } = item;
            if (!acc[brand]) {
              acc[brand] = [];
            }
            acc[brand].push({
              model,
              colors,
              storage,
            });
            return acc;
          }, {});
          setBrandModels(transformedData);
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
        });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const verifyToken = jwtDecode(token);
    const requestBody = { email: verifyToken.email };
    axios.post(`${baseURL}/party/getAllParties`, requestBody)
        .then(response => {
          setParties(response.data);
        })
        .catch(error => {
          console.error('Error fetching parties: ', error);
        });
  }, []);

  useEffect(() => {
    // Update IMEIList when quantity changes
    const newIMEIList = Array.from({ length: quantity }, () => []);
    setIMEIList(newIMEIList);
    setColor(Array(quantity).fill(''));
    setStorage(Array(quantity).fill(''));
  }, [quantity]);

  const handlePassword = (e) => {
    e.preventDefault();
    const uniqueIdentifier = "123456";
    if (password === uniqueIdentifier) {
      setShowForm(true);
    }
  };

  const handleAddIMEI = (index) => {
    const updatedIMEIList = [...IMEIList];
    updatedIMEIList[index] = [...updatedIMEIList[index], ''];
    setIMEIList(updatedIMEIList);
  };

  const handleIMEIChange = (parentIndex, childIndex, value) => {
    const updatedIMEIList = [...IMEIList];
    updatedIMEIList[parentIndex][childIndex] = value;
    setIMEIList(updatedIMEIList);
  };

  const handleColorChange = (event, index) => {
    const selectedColor = event.target.value;
    const updatedColors = [...Color];
    updatedColors[index] = selectedColor;
    setColor(updatedColors);
  };

  const handleStorageChange = (event, index) => {
    const selectedStorage = event.target.value;
    const updatedStorage = [...Storage];
    updatedStorage[index] = selectedStorage;
    setStorage(updatedStorage);
  };

  const handleSubmitButton = async (event) => {
    event.preventDefault();

    // Preliminary check to ensure fields are not empty
    if (
        CustomerName === "" ||
        CustomerPhoneNumber === "" ||
        BrandName === "" ||
        Color.length === 0 ||
        Model === "" ||
        Price === "" ||
        NetworkStatus === "" ||
        Storage.length === 0 ||
        IMEIList.some(imei => imei.length === 0) // Check if any IMEI is empty
    ) {
      openSnackbar('error', 'Please fill in all the required fields.');
      return;
    }

    const token = localStorage.getItem('token');
    const verifyToken = jwtDecode(token);

    const bulkRequest = Array.from({ length: quantity }).map((_, index) => {
      const color = Color[index] || "";
      const storage = Storage[index] || "";
      const imeiList = IMEIList[index] || [];

      // Check for each individual request's validity
      if (
          !color ||
          !storage ||
          imeiList.length === 0
      ) {
        openSnackbar('error', 'Please provide valid details for each entry.');
        return null; // Return null for invalid entry
      }

      return {
        Email: verifyToken.email,
        CNIC: verifyToken.cnic,
        BrandName: BrandName,
        Color: color,
        Model: Model,
        PurchasingPrice: Price,
        NetworkStatus: NetworkStatus,
        Storage: storage,
        IMEIList: imeiList,
        PurchaseDate: selectedDate,
        CustomerName: CustomerName,
        CustomerCNIC: CustomerCNIC || "NA",
        CustomerAddress: CustomerAddress || "NA",
        CustomerPhoneNumber: CustomerPhoneNumber,
        PurchaserName: verifyToken.name,
        PurchaserCNIC: verifyToken.cnic,
        PurchaserAddress: verifyToken.address,
        PurchaserPhoneNumber: verifyToken.phoneNumber,
        SellingPrice: 0,
      };
    }).filter(request => request !== null); // Filter out invalid entries

    // If bulkRequest is empty, show an error
    if (bulkRequest.length === 0) {
      openSnackbar('error', 'No valid entries to submit.');
      return;
    }

    console.log('Bulk Request:', bulkRequest); // Debug log for bulk request

    try {
      const response = await fetch(`${baseURL}/mobile/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(bulkRequest),
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Data added successfully');
        // Clear the form after successfully adding the items
        setBrand('');
        setSelectedModel('');
        setColor([]);
        setStorage([]);
        setNetworkStatus('');
        setIMEIList([]);
        setPrice('');
        setSelectedDate(new Date());
        setCustomerName('');
        setCustomerCNIC('');
        setCustomerAddress('');
        setCustomerPhoneNumber('');
        openSnackbar('success', 'Phones added successfully');
        // setShowForm(false);
        // window.location.reload();
      } else if (response.status === 409) {
        console.error('One or more IMEI numbers are already in use.');
        openSnackbar('error', 'One or more IMEI numbers are already in use.');
      } else {
        console.error('Error:', data);
        openSnackbar('error', 'An unexpected error occurred.');
      }
    } catch (error) {
      console.error('Error:', error);
      openSnackbar('error', 'Failed to add phones. Please try again.');
    }
  };



  const openSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleBrandChange = (event) => {
    const selectedBrand = event.target.value;
    setBrand(selectedBrand);
    setModel('');
    setColor([]);
    setStorage([]);
    setSelectedModel('');
    setIMEIList(Array.from({ length: quantity }, () => []));
  };

  const handleNetworkStatus = (event) => {
    const networkstatus = event.target.value;
    setNetworkStatus(networkstatus);
  };

  const handleModelChange = (event, value) => {
    const selectedModel = value;
    setModel(selectedModel);
    setSelectedModel(selectedModel);
  };

  const handlePriceChange = (event) => {
    const price = event.target.value;
    setPrice(price);
  };

  const handleUseSavedPartyChange = (event) => {
    setUseSavedParty(event.target.value === 'saved');
  };

  const handlePartySelect = (event, newValue) => {
    const selectedParty = newValue || null;
    setSelectedParty(selectedParty);

    if (selectedParty) {
      setCustomerName(selectedParty.name);
      setCustomerCNIC(selectedParty.cnic);
      setCustomerAddress(selectedParty.address);
      setCustomerPhoneNumber(selectedParty.contactNo);
    } else {
      setCustomerName('');
      setCustomerCNIC('');
      setCustomerAddress('');
      setCustomerPhoneNumber('');
    }
  };



  const NetworkStatusItems = [
    { key: 'Non-PTA', label: 'Non-PTA' },
    { key: 'PTA-Approved', label: 'PTA-Approved' },
    { key: 'Non-Active', label: 'Non-Active' },
    { key: 'IMEI Patched', label: 'IMEI Patched' },
    { key: 'JV', label: 'JV' },
    { key: 'CPID', label: 'CPID' },
    { key: 'MobilePartner', label: 'MobilePartner' },
    { key: 'Box Packed PTA-Approved', label: 'Box Packed PTA-Approved' },
    { key: 'Box Packed Non PTA', label: 'Box Packed Non PTA' },
    { key: 'PTA Approved with Box', label: 'PTA Approved with Box' },
    { key: 'Non-PTA Approved with Box', label: 'Non-PTA Approved with Box' }
  ];

  const sortedMenuItems = NetworkStatusItems.map(status => status.label);

  return (
      <Box>
        <NavBar />
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              maxWidth: '800px',
              margin: '0 auto',

              padding: '20px',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              borderRadius: '8px',
            }}
            noValidate
            autoComplete="off"
            onSubmit={handlePassword}
        >
          {!showForm && (
              <>
                <Typography variant="h5" component="h1" gutterBottom>
                  Enter Password to Proceed
                </Typography>
                <TextField
                    id="password"
                    label="Password"
                    type="password"

                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                  Submit
                </Button>
              </>
          )}
          {showForm && (
              <>
                <Typography variant="h5" component="h1" gutterBottom>
                  Add Stock
                </Typography>
                <Box mb={2}>
                  <Typography variant="h6">Select Party Information</Typography>
                  <RadioGroup row value={useSavedParty ? 'saved' : 'new'} onChange={handleUseSavedPartyChange}>
                    <FormControlLabel value="saved" control={<Radio />} label="Use Saved Party" />
                    <FormControlLabel value="new" control={<Radio />} label="Enter New Party" />
                  </RadioGroup>
                  {useSavedParty ? (
                      <FormControl fullWidth margin="normal">
                        <Autocomplete
                            value={selectedParty}
                            onChange={handlePartySelect}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            options={parties}
                            getOptionLabel={(party) => party.name || ''}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Saved Party" variant="outlined" />
                            )}
                            renderOption={(props, party) => (
                                <li {...props} key={party._id}>
                                  {party.name}
                                </li>
                            )}
                        />

                      </FormControl>
                  ) : (
                      <>
                        <TextField
                            id="customer-name"
                            label="Customer Name"
                            value={CustomerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            id="customer-cnic"
                            label="Customer CNIC"
                            value={CustomerCNIC}
                            onChange={(e) => setCustomerCNIC(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            id="customer-address"
                            label="Customer Address"
                            value={CustomerAddress}
                            onChange={(e) => setCustomerAddress(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            id="customer-phone-number"
                            label="Customer Phone Number"
                            value={CustomerPhoneNumber}
                            onChange={(e) => setCustomerPhoneNumber(e.target.value)}
                            fullWidth
                        />
                      </>
                  )}
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box mb={2}>
                  <Typography variant="h6">Enter Product Information</Typography>
                  <TextField
                      id="brand"
                      label="Brand"
                      value={BrandName}
                      onChange={handleBrandChange}
                      select
                      fullWidth
                  >
                    {Object.keys(brandModels).map((brand, index) => (
                        <MenuItem key={index} value={brand}>
                          {brand}
                        </MenuItem>
                    ))}
                  </TextField>
                  <Autocomplete
                      id="model"
                      options={(brandModels[BrandName] || []).map((item) => item.model)}
                      value={selectedModel}
                      onChange={handleModelChange}
                      renderInput={(params) => <TextField {...params} label="Model" fullWidth />}
                  />
                  <TextField
                      id="quantity"
                      label="Quantity"
                      type="number"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value === '' ? 0 : parseInt(e.target.value))}
                      fullWidth
                  />
                  <TextField
                      id="price"
                      label="Price"
                      value={Price}
                      onChange={handlePriceChange}
                      fullWidth
                  />
                  <FormControl fullWidth>
                    <InputLabel>Network Status</InputLabel>
                    <Select
                        id="network-status"
                        value={NetworkStatus}
                        onChange={handleNetworkStatus}
                    >
                      {sortedMenuItems.map((status, index) => (
                          <MenuItem key={index} value={status}>
                            {status}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box mb={2}>
                  <Typography variant="h6">Enter Phone Details</Typography>
                  {Array.from({ length: quantity }).map((_, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Phone {index + 1}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                              id={`color-${index}`}
                              label="Color"
                              value={Color[index]}
                              onChange={(event) => handleColorChange(event, index)}
                              select
                              fullWidth
                          >
                            {(brandModels[BrandName]?.find((item) => item.model === Model)?.colors || []).map((color, idx) => (
                                <MenuItem key={idx} value={color}>
                                  {color}
                                </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                              id={`storage-${index}`}
                              label="Storage"
                              value={Storage[index]}
                              onChange={(event) => handleStorageChange(event, index)}
                              select
                              fullWidth
                          >
                            {(brandModels[BrandName]?.find((item) => item.model === Model)?.storage || []).map((storage, idx) => (
                                <MenuItem key={idx} value={storage}>
                                  {storage}
                                </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          {IMEIList[index]?.map((imei, i) => (
                              <TextField
                                  key={i}
                                  label={`IMEI ${i + 1}`}
                                  value={imei}
                                  onChange={(event) => handleIMEIChange(index, i, event.target.value)}
                                  fullWidth
                              />
                          ))}
                          <Button onClick={() => handleAddIMEI(index)} variant="contained" color="secondary" sx={{ mt: 1 }}>
                            Add IMEI
                          </Button>
                        </Grid>
                      </Grid>
                  ))}
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box mb={2}>
                  <Typography variant="h6">Select Purchase Date</Typography>
                  <DatePicker
                      value={selectedDate}
                      onChange={handleDateChange}
                      format="yyyy-MM-dd"
                      fullWidth
                  />
                </Box>
                <Button variant="contained" color="primary" onClick={handleSubmitButton} fullWidth>
                  Add Stock
                </Button>
              </>
          )}
        </Box>
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={closeSnackbar}
        >
          <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
  );
}
